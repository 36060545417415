import React from "react";
import { Button, Grid, Typography } from "@mui/material";

const DetailModal = ({ details, onCancel, nairaFormatter }) => {
  return (
    <Grid
      container
      sx={{
        width: "500px",
        maxHeight: "100vh",
        padding: "30px 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowX: "scroll",
      }}
      className="scrollbar"
    >
      <Grid
        item
        width={"80%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Typography
          sx={{
            marginBottom: "12px",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "3px",
            color: "#000000",
            fontFamily: "Open-Sans",
          }}
        >
         Transaction Details
        </Typography>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <tbody
            style={{
              margin: "0px auto",
            }}
          >
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Vendor</h5>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-d-cont">
                  <p>{details?.vendorName ?? "-"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Network</h5>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-d-cont">
                  <p
                    style={{
                      background:
                        details?.networkProviderName === "MTN"
                          ? "#FFD953"
                          : details?.networkProviderName === "GLO"
                          ? "#72F651"
                          : details?.networkProviderName === "AIRTEL"
                          ? "#FF543D"
                          : "#3C8702",
                      color:
                        details?.networkProviderName === "MTN" ||
                        details?.networkProviderName === "GLO"
                          ? "#000"
                          : "#fff",
                      height: "25px",
                      padding: "0px 12px",
                      borderRadius: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {details?.networkProviderName}
                  </p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">

                  <h5>Channel</h5>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-d-cont">
                  <p>{details?.channelName ?? "-"}</p>
                </div>
              </td>
            </tr>

            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">

                  <h5>Acc No</h5>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-d-cont">
                  <p>{details?.accountNumber ?? "-"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Phone No</h5>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-d-cont">
                  <p>{details?.msisdn ?? "-"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Amount</h5>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-d-cont">
                  <p>{nairaFormatter(details?.amount) ?? "-"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">

                  <h5>Internal Reference</h5>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-d-cont">
                  <p>{details?.internalReference ?? "-"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Processed</h5>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-d-cont">
                  <div
                    style={{
                      display: "flex",
                      gap: "6px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: details?.processed
                          ? "#1CC218"
                          : "#FF543D",
                        width: "8px",
                        height: "8px",
                        borderRadius: "100%",
                      }}
                    ></div>
                    <p>{details?.processed ? "True" : "False" ?? "-"}</p>
                  </div>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Status</h5>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-d-cont">
                  <p>{details?.status?.split(/(?=[A-Z])/)?.join(" ") ?? "-"}</p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Final Transaction Status</h5>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-d-cont">
                  <p>
                    {details?.finalTransactionStatus
                      ?.split(/(?=[A-Z])/)
                      ?.join(" ") ?? "-"}
                  </p>
                </div>
              </td>
            </tr>
            {details?.failureReason !== 0 && (
              <tr className="rule-approval-table-row">
                <td className="rule-approval-table-data">
                  <div className="rule-approval-table-data-cont">
                    <h5>Failure Reason</h5>
                  </div>
                </td>
                <td className="rule-approval-table-data">
                  <div className="rule-approval-table-data-d-cont">
                    <p>
                      {details?.failureReason?.split(/(?=[A-Z])/)?.join(" ") ??
                        "-"}
                    </p>
                  </div>
                </td>
              </tr>
            )}
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Transaction Date</h5>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-d-cont">
                  <p
                    style={{
                      backgroundColor: "#EEF0F4",
                      borderRadius: "30px",
                      height: "25px",
                      padding: "0px 12px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {details?.transactionDate?.substring(0, 10) ?? "-"}
                  </p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">
                  <h5>Transaction Time</h5>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-d-cont">
                  <p
                    style={{
                      backgroundColor: "#EEF0F4",
                      borderRadius: "30px",
                      height: "25px",
                      padding: "0px 12px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {details?.transactionDate?.substring(11, 19) ?? "-"}
                  </p>
                </div>
              </td>
            </tr>
            <tr className="rule-approval-table-row">
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-cont">

                  <h5>Debit Response Message</h5>
                </div>
              </td>
              <td className="rule-approval-table-data">
                <div className="rule-approval-table-data-d-cont">
                  <p>{details?.debitResponseMessage ?? "-"}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <Button
          variant="contained"
          sx={{
            background: "#255cff",
            textTransform: "capitalize",
            marginTop: "40px",
            fontFamily: "Open-Sans",
            "&:hover": {
              backgroundColor: "#255cff",
            },
          }}
          onClick={() => {
            onCancel(false);
          }}
        >
          Close
        </Button>
      </Grid>
    </Grid>
  );
};

export default DetailModal;
