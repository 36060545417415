import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import ruleActiveImg from "../../../assets/svg/ruleActiveImg.svg";
import vendorActive from "../../../assets/svg/vendorActive.svg";
import userActiveImg from "../../../assets/svg/vendorActive.svg";
import rule2ActiveImg from "../../../assets/svg/rule2ActiveImg.svg";

//import speedActive from "../../../assets/svg/speedActive.svg";
//import CallMadeIcon from "@mui/icons-material/CallMade";
//import CallReceivedIcon from "@mui/icons-material/CallReceived";

const CardSection = ({ vendors, users, networkrules, channelrules }) => {
  const cards = [
    {
      imgActive: ruleActiveImg,
      number: networkrules,
      title: "Network Rules",
      path: "/admin/configuration",
    },
    {
      imgActive: userActiveImg,
      number: channelrules,
      title: "Channel Rules",
      path: "/admin/configuration",
    },

    {
      imgActive: vendorActive,
      number: vendors,
      title: "Active Vendors",
      path: "/admin/configuration",
    },
    {
      imgActive: rule2ActiveImg,
      number: users,
      title: "Active Users",
      path: "/admin/users",
    },
  ];

  return (
    <div>
      <div className="card-bg">
        <div className="cards-container">
          {cards.map((card, index) => (
            <div
              key={index}
              className="cards"
              style={{
                background:
                  index === 0
                    ? "#D6EB77"
                    : index === 1
                    ? "#BBEAFF"
                    : index === 2
                    ? "#F5C7FF"
                    : "#FFFFFF",
              }}
            >
              <div className="card-info">
                <p
                  className="card-title"
                  style={{
                    background:
                      index === 0
                        ? "#B5D32B"
                        : index === 1
                        ? "#6DCDF9"
                        : index === 2
                        ? "#E490F6"
                        : "#F0F0F0",
                  }}
                >
                  {card.title}
                </p>
                <div
                  style={{
                    width: "84px",
                    height: "84px",
                    fontFamily: "Poppins",
                  }}
                >
                  <CircularProgressbar
                    value={card.number}
                    text={card.number}
                    styles={buildStyles({
                      textColor: "#000",
                      textSize: "24px",
                      trailColor:
                        index === 0
                          ? "#B5D32B"
                          : index === 1
                          ? "#6DCDF9"
                          : index === 2
                          ? "#E490F6"
                          : "#F0F0F0",
                      pathColor: "#081226",
                    })}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardSection;
