const Validation = (values) => {
  let errors = {};
  const regex = /[^A-Za-z0-9]/g;
  if (values.email === "") {
    errors.email = "Username required";
  } else if (values.email.length < 3) {
    errors.email = "Username can not be less than 3 characters";
  } else if (regex.test(values.email)) {
    errors.email = "Username cannot contain special character";
  } else if (values.email.length > 30) {
    errors.email = "Username can not be more than 30 characters";
  } else {
    errors.email = "";
  }

  if (values.password === "") {
    errors.password = "Password required";
  } else if (values.password.length < 3) {
    errors.password = "Password can not be less than 3 characters";
  } else if (values.password.length > 30) {
    errors.password = "Password can not be more than 30 characters";
  } else {
    errors.password = "";
  }

  return errors;
};

export default Validation;
