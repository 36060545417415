import React, { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { Card } from "@mui/material";
import { userService } from "../../utils/api";
import CenterModal from "../../components/modal/center";

//icons
import TimerIcon from "@mui/icons-material/Timer";

import "../../styles/users.css";

const AddSystemUser = () => {
  //const creatorEmail = JSON.parse(sessionStorage.getItem("user")).email;
  const [closeModal, setCloseModal] = useState(false);
  const [response, setResponse] = useState("");

  const initialValue = {
    email: "",
    userName: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Please enter your email address"),
    userName: Yup.string().required("Username is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .matches(/[A-Z]/, "Password must have at least 1 uppercase character")
      .matches(/[a-z]/, "Password must have at least 1 lowercase character")
      .matches(/[0-9]/, "Password must have at least 1 digit character")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Password does not match")
      .required("Please confirm your password"),
  });

  const handleSubmit = async (formData) => {
    try {
      const response = await userService.addSystemUser({
        email: formData.email,
        userName: formData.userName,
        password: formData.password,
      });

      if (response?.data?.succeeded) {
        setResponse(response?.data?.message);
        setCloseModal(true);
      } else {
        toast.error(response?.data?.message, {
          className: "toast-error",
          theme: "colored",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="dashboard-container">
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              width: "98%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div className="user-header-title-div">
              <h2 className="user-header-title-text">Add System User</h2>
            </div>

            <div style={{ display: "flex" }}>
              <div className="time">
                <TimerIcon />
                <p>Time: {moment().format("h:mm:ss a")}</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className="ruleConfig-header">
            Add a system user to the application.
          </p>
        </div>
        <Card
          sx={{
            background: "#F3F5FF",
            width: "100%",
            paddingTop: "10px",
            boxShadow: "none",
          }}
        >
          <div className="user-table-header">
            <div className="user-table-header-div">
              <h2 className="user-table-header-text"> Add System User</h2>
            </div>
          </div>
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValue}
            validationSchema={validationSchema}
          >
            <Form className="new-user-form">
              <div className="new-user-form-input-cont">
                <label htmlFor="userName" className="new-user-form-label">
                  Username
                </label>
                <div>
                  <Field
                    type="text"
                    name="userName"
                    className="new-user-form-input"
                    placeholder="Enter username"
                  />
                  <ErrorMessage
                    name="userName"
                    component="p"
                    style={{ color: "red" }}
                  />
                </div>
              </div>

              <div className="new-user-form-input-cont">
                <label htmlFor="email" className="new-user-form-label">
                  Email
                </label>
                <div>
                  <Field
                    type="email"
                    name="email"
                    className="new-user-form-input"
                    placeholder="Enter email address"
                  />
                  <ErrorMessage
                    name="email"
                    component="p"
                    style={{ color: "red" }}
                  />
                </div>
              </div>
              <div className="new-user-form-input-cont">
                <label htmlFor="email" className="new-user-form-label">
                  Password
                </label>
                <div>
                  <Field
                    type="password"
                    name="password"
                    className="new-user-form-input"
                    placeholder="Enter Password"
                  />
                  <ErrorMessage
                    name="password"
                    component="p"
                    style={{ color: "red" }}
                  />
                </div>
              </div>
              <div className="new-user-form-input-cont">
                <label htmlFor="email" className="new-user-form-label">
                  Confirm Password
                </label>
                <div>
                  <Field
                    type="password"
                    name="confirmPassword"
                    className="new-user-form-input"
                    placeholder="Confirm Password"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="p"
                    style={{ color: "red" }}
                  />
                </div>
              </div>
              <div className="new-user-form-button-footer">
                <p className="new-user-form-text">
                  Please provide the correct Information required.
                </p>
                <div className="new-user-form-button-cont">
                  <button
                    type="submit"
                    style={{
                      background: "#255CFF",
                      color: "#ffffff",
                      marginLeft: "auto",
                    }}
                    className="new-user-form-button"
                  >
                    Add User
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </Card>
        <p className="copyright-text">&copy; 2024 Breeze Transaction Gateway. </p>
      </div>
      <CenterModal
        isOpen={closeModal}
        toggleModal={() => {
          setCloseModal(!closeModal);
        }}
      >
        <div
          style={{
            width: "500px",
            height: "400px",
            paddingLeft: "20px",
            paddingRight: "20px",
            fontFamily: "Open-Sans",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h1>User created successfully</h1>

          <p
            style={{ color: "#959595", marginTop: "25px", textAlign: "center" }}
          >
            Password: {response}
          </p>
          <p style={{ marginTop: "20px", textAlign: "center" }}>
            Please ensure to securely copy the password before closing the
            modal, and remember to store it in a safe location as retrieval will
            not be possible afterward.
          </p>
          <div style={{ marginTop: "20px", display: " flex", gap: "30px" }}>
            <button
              style={{
                background: "#255CFF",
                color: "#fff",
                marginTop: "20px",
              }}
              className="new-user-form-button"
              onClick={() => {
                navigator.clipboard.writeText(response);
                toast.success("Copied to clipboard", {
                  className: "toast-success",
                  theme: "colored",
                });
              }}
            >
              Copy
            </button>
            <button
              style={{
                background: "#ffffff",
                color: "#255CFF",
                border: "1px solid #255CFF",
                marginTop: "20px",
              }}
              className="new-user-form-button"
              onClick={() => setCloseModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      </CenterModal>
    </div>
  );
};

export default AddSystemUser;
