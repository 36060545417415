// Library methods
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";

// Components
import CardActiveSection from "./CardActiveSection";
import TrafficActiveSection from "./TrafficActiveSection";

// MUI Components
import TimerIcon from "@mui/icons-material/Timer";

// Utilities
import "../../../styles/dashboardContent.css";
import { dashboardService, transactionService } from "../../../utils/api";
import TransactionList from "./TransactionList";
// import NoTransaction from "./NoTransaction";

const DashboardContent = () => {
  const [cardData, setCardData] = useState([]);
  const [transactionData, setTransactionData] = useState({});
  const actor = JSON.parse(sessionStorage.getItem("user"));

  const fetchCardData = async () => {
    try {
      const response = await dashboardService.getCardData();
      setCardData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTransactions = useCallback(async () => {
    try {
      const response = await transactionService.getAllTransactions();
      setTransactionData(response?.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  useEffect(() => {
    fetchCardData();
  }, []);

  const transformText = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };
  return (
    <div className="dashboard-container">
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "94%",
          }}
        >
          <div>
            <h2 className="dash-header">
              Welcome {transformText(actor?.userName)}
            </h2>
          </div>
          <div style={{ display: "flex", columnGap: "20px" }}>
            <div className="time">
              <TimerIcon />
              <p>Time: {moment().format("h:mm:ss a")} </p>
            </div>
          </div>
        </div>
        <div>
          <CardActiveSection
            vendors={cardData?.vendors}
            users={cardData?.users}
            networkrules={cardData?.networkRules}
            channelrules={cardData?.channelRules}
          />
          {<TransactionList />}
          <TrafficActiveSection />
        </div>
      </div>
      <p className="copyright-text">&copy; 2024 Breeze Transaction Gateway. </p>
    </div>
  );
};

export default DashboardContent;
