import React, { useEffect, useState } from "react";
import { Divider } from "@mui/material";
//import jwt_decode from "jwt-decode";

import DashboardIcon from "@mui/icons-material/Dashboard";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import BarChartIcon from "@mui/icons-material/BarChart";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import LogoutIcon from "@mui/icons-material/Logout";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import PeopleIcon from "@mui/icons-material/People";
import CachedIcon from "@mui/icons-material/Cached";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import logo from "../../../assets/svg/logo.svg";
//import share from "../../../assets/svg/share.svg";
//import activity from "../../../assets/svg/activity.svg";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
// import LightModeIcon from '@mui/icons-material/LightMode';
// import NightlightRoundOutlinedIcon from '@mui/icons-material/NightlightRoundOutlined';
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import CenterModal from "../../../components/modal/center";
import Idle from "../../../components/IdleUser";

import { useIdleTimer } from "react-idle-timer";
import { userService } from "../../../utils/api";
import { useUser } from "../../../utils/hooks/redux.hook";

const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [doAutoLogout, setDoAutoLogout] = useState(true);
  const [userActive, setUserActive] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const actor = JSON?.parse(sessionStorage.getItem("user"));

  const { admin } = useUser();
  // const user = jwt_decode(actor.jwToken);
  // console.log(user);

  const nameSlice = (name) => {
    return name.substring(0, 1).toUpperCase();
  };

  const transformText = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  //temporary logout... an endpoint that requires passing the refresh token should be provided
  const handleLogout = async () => {
    try {
      await userService?.logOutUser(admin?.admin?.email).then((res) => {
        toast.success("Logging Out...", {
          className: "toast-success",
          theme: "colored",
        });
        sessionStorage.clear();
        setTimeout(function () {
          window.location = "/auth/login";
        }, 1500);
      });
    } catch (error) {
      toast.error(error, {
        className: "toast-success",
        theme: "colored",
      });
    }
  };

  const handleOnIdle = (event) => {
    console.log("user is idle", event);
    setUserActive(false);
    setDoAutoLogout(false);
    setShowModal(true);
    setTimeout(function () {
      setDoAutoLogout(true);
    }, 1000 * 30);
    console.log("last active", getLastActiveTime());
  };

  const handleOnActive = (event) => {
    console.log("user is active", event);
    setDoAutoLogout(false);
    setUserActive(true);
    console.log("time remaining", getRemainingTime());
  };

  const handleOnAction = (event) => {
    console.log("user did something", event);
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 10,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  useEffect(() => {
    if (doAutoLogout && !userActive) {
      window.location = "/auth/login";
    }
  }, [doAutoLogout, userActive]);

  const menuItems = [
    {
      path: "/admin/dashboard-active",
      name: "Dashboard",
      icon: <DashboardIcon />,
      authorizedRoles: ["INITIATOR", "APPROVER", "SUPERADMIN"],
    },

    {
      path: "/admin/configuration",
      name: "Configurations",
      icon: <WorkspacesIcon />,
      authorizedRoles: ["INITIATOR", "SUPERADMIN"],
    },
    {
      path: "/admin/settlement",
      name: "Settlement Config",
      icon: <WorkspacesIcon />,
      authorizedRoles: ["SETTLEMENT_INITIATOR", "SUPERADMIN"],
    },

    {
      path: "/admin/rules-approval",
      name: "Approvals",
      icon: <CheckBoxIcon />,
      authorizedRoles: ["APPROVER", "SUPERADMIN"],
    },

    {
      path: "/admin/settlement-approval",
      name: "Settlement Approval",
      icon: <CheckBoxIcon />,
      authorizedRoles: ["SETTLEMENT_APPROVER", "SUPERADMIN"],
    },
    {
      path: "/admin/settlement-history",
      name: "Settlement History",
      icon: <AccessTimeIcon />,
      authorizedRoles: ["INITIATOR", "APPROVER", "SUPERADMIN"],
    },
    {
      path: "/admin/user-approval",
      name: "User Approval",
      icon: <PanoramaFishEyeIcon />,
      authorizedRoles: ["USERMANAGER_APPROVER", "SUPERADMIN"],
    },
    {
      path: "/admin/users",
      name: "Users",
      icon: <PeopleIcon />,
      authorizedRoles: ["USERMANAGER_INITIATOR", "SUPERADMIN"],
    },
    {
      path: "/admin/settings",
      name: "Settings",
      icon: <AppSettingsAltIcon />,
      authorizedRoles: ["INITIATOR", "SUPERADMIN"],
    },
    {
      path: "/admin/activity",
      name: "Activity Log",
      icon: <CachedIcon />,
      authorizedRoles: ["AUDITOR", "SUPERADMIN"],
    },
    // {
    //   path: "/admin/page-access",
    //   name: "Page Access",
    //   icon: <ImportContactsIcon />,
    //   authorizedRoles: ["SUPERADMIN"],
    // },
  ];
  return (
    <div
      style={{
        width: isOpen ? "10%" : "20px",
        height: "100%",
        paddingLeft: isOpen ? "18rem" : "50px",
        marginRight: isOpen ? "20px" : "0",
        paddingTop: "70px",
      }}
    >
      <div style={{ width: isOpen ? "328px" : "70px" }} className="sidebar">
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginBottom: "40px" }}>
            <div
              style={{ marginLeft: isOpen ? "280px" : "8px" }}
              className="bars"
            >
              {isOpen ? (
                <ArrowBackIosOutlinedIcon
                  onClick={toggle}
                  style={{ cursor: "pointer", width: "20px" }}
                />
              ) : (
                <ArrowForwardIosIcon
                  onClick={toggle}
                  style={{ cursor: "pointer", width: "20px" }}
                />
              )}
            </div>

            <div className="top_section">
              <img
                className="logo"
                style={{ display: isOpen ? "block" : "none" }}
                src={logo}
                alt="logo"
              />
              {/* <img className="loo"  src={mobileLogo} alt='logo' /> */}
            </div>
            <Divider
              sx={{ margin: isOpen ? "-15px 30px 34px" : "-15px 5px 34px" }}
            />
            {menuItems.map((item, index) => {
              if (item?.authorizedRoles?.includes(actor.roles[0])) {
                return (
                  <NavLink
                    to={item.path}
                    key={index}
                    className="link"
                    activeclassname="active"
                    style={{ marginLeft: isOpen ? "25px" : "5px" }}
                  >
                    {item.icon}
                    <div
                      style={{ display: isOpen ? "block" : "none" }}
                      className="link_text"
                    >
                      {item.name}
                    </div>
                  </NavLink>
                );
              } else {
                return null;
              }
            })}
            <Divider sx={{ margin: "20px 0px" }} />

            <NavLink
              to={"/admin/help"}
              className="link"
              activeclassname="active"
              style={{ marginLeft: isOpen ? "25px" : "5px" }}
            >
              <LiveHelpIcon />
              <div
                style={{ display: isOpen ? "block" : "none" }}
                className="link_text"
              >
                Help & Support
              </div>
            </NavLink>
            <div
              className="link"
              activeclassname="active"
              style={{ marginLeft: isOpen ? "25px" : "5px", cursor: "pointer" }}
            >
              <LogoutIcon />
              <div
                style={{ display: isOpen ? "block" : "none" }}
                className="link_text"
                onClick={() => handleLogout()}
              >
                Logout
              </div>
            </div>
          </div>

          <>
            {isOpen && (
              <div className="sidebar-loggedin-user">
                <>
                  <div className="sidebar-loggedin-logo">
                    {nameSlice(actor?.userName)}
                  </div>
                  <div className="sidebar-loggedin-info">
                    <div className="sidebar-loggedin-name">
                      {transformText(actor?.userName)}
                    </div>
                    <div className="sidebar-loggedin-role">
                      {actor?.roles[0]}
                    </div>
                  </div>
                </>
              </div>
            )}
          </>
          {/* <footer>
                  <div className='sidebar-toggle'>
                      <div className='lightMode-bg'>
                          <LightModeIcon style={{marginTop: '3px'}} />
                      </div>
                      <div>
                          <NightlightRoundOutlinedIcon style={{marginTop: '9px', color: '#737373'}} />
                      </div>
                  </div>
              </footer> */}
        </div>
      </div>

      <CenterModal
        isOpen={showModal}
        toggleModal={() => {
          setShowModal(!showModal);
        }}
      >
        <Idle
          cancel={() => {
            setShowModal(false);
            setDoAutoLogout(false);
          }}
        />
      </CenterModal>
    </div>
  );
};

export default SideBar;
