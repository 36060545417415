import { useSelector, useDispatch } from "react-redux";
import { removeUser, setUser } from "../../store/slices/user/slice";

export const useUser = () => {
  const admin = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  console.log(admin);

  return {
    admin,
    setUserDispatch: (user) => dispatch(setUser(user)),
    logOut: () => dispatch(removeUser()),
    // isSignedIn: !!admin.id,
  };
};
