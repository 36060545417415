import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";

const PriorityModal = ({ details, onConfirm, setIsRejectOpen, onCancel }) => {
  const actorEmail = JSON.parse(sessionStorage.getItem("user")).email;
  const data = JSON?.parse(details?.data);
  console.log(data);
  return (
    <Grid container py={8} px={6} width={"400px"}>
      <Grid item width={"100%"}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "36px",
            color: "#000",
            fontFamily: "Open-Sans",
          }}
        >
          PRIORITY APPROVAL
        </Typography>

        <Box display={"flex"} justifyContent={"space-between"} my={1}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "36px",
              fontFamily: "Open-Sans",
            }}
          >
            Modifier:
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "36px",
              fontFamily: "Open-Sans",
            }}
          >
            {details?.initiatorEmail}
          </Typography>
        </Box>

        {data?.map((item) => (
          <Box key={item.id} display={"flex"} justifyContent={"space-between"}>
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "36px",
                fontFamily: "Open-Sans",
              }}
            >
              {item.Name}
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "36px",
                fontFamily: "Open-Sans",
              }}
            >
              Priority {item.Priority}
            </Typography>
          </Box>
        ))}
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "36px",
              fontFamily: "Open-Sans",
            }}
          >
            Approval Status
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "36px",
              fontFamily: "Open-Sans",
            }}
          >
            {details?.approvalStatus}
          </Typography>
        </Box>

        {details?.rejectionReason !== null && (
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "36px",
                fontFamily: "Open-Sans",
              }}
            >
              Rejection Reason
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "36px",
                fontFamily: "Open-Sans",
              }}
            >
              {details?.rejectionReason}
            </Typography>
          </Box>
        )}

        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "36px",
              fontFamily: "Open-Sans",
            }}
          >
            Date Created
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "36px",
              fontFamily: "Open-Sans",
            }}
          >
            {details?.dateCreated?.substring(0, 10) ?? "-"}
          </Typography>
        </Box>

        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "36px",
              fontFamily: "Open-Sans",
            }}
          >
            Time Created
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "36px",
              fontFamily: "Open-Sans",
            }}
          >
            {details?.dateCreated?.substring(11, 16) ?? "-"}
          </Typography>
        </Box>

        <Box
          py={2}
          display={details?.approvalStatus === "PENDING" ? "flex" : "none"}
          justifyContent={"space-around"}
        >
          <Button
            variant="contained"
            sx={{
              background: "#fff",
              textTransform: "capitalize",
              color: "#255cff",
              border: "2px solid #255cff",
              fontFamily: "Open-Sans",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#B3B3B3",
              },
            }}
            onClick={() => {
              setIsRejectOpen(true);
              onCancel(false);
            }}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            sx={{
              background: "#255cff",
              textTransform: "capitalize",
              fontFamily: "Open-Sans",
              "&:hover": {
                backgroundColor: "#255cff",
              },
            }}
            onClick={() => {
              onConfirm(actorEmail, details?.id);
              onCancel(false);
            }}
          >
            Approve
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PriorityModal;
