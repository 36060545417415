// Library methods
import React, { useState } from "react";
import moment from "moment";

//icons
import TimerIcon from "@mui/icons-material/Timer";

// Components
import AllUsers from "./AllUsers";
import AddUser from "./AddUser";

import "../../styles/users.css";

const Users = () => {
  const [openAddUser, setOpenAddUser] = useState(false);

  return (
    <div className="dashboard-container">
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              width: "98%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div className="user-header-title-div">
              <h2 className="user-header-title-text">Add Users</h2>
              <div className="user-header-title-super">
                <h4>Super Admin</h4>
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div className="time">
                <TimerIcon />
                <p>Time: {moment().format("h:mm:ss a")}</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className="ruleConfig-header">
            Add or remove users from application, assign roles to each user, and
            also do not forget to save and submit.
          </p>
        </div>

        {openAddUser ? (
          <AddUser setOpenAddUser={setOpenAddUser} />
        ) : (
          <AllUsers setOpenAddUser={setOpenAddUser} />
        )}
      </div>
      <p className="copyright-text">&copy; 2024 Breeze Transaction Gateway. </p>
    </div>
  );
};

export default Users;
