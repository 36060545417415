import * as React from "react";
import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
//import FormControlLabel from "@mui/material/FormControlLabel";
//import Checkbox from "@mui/material/Checkbox";
//import { deepOrange } from "@mui/material/colors";
import "../../../styles/ruleConfig.css";
import TimerIcon from "@mui/icons-material/Timer";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { InputLabel, MenuItem, Select } from "@mui/material";
import { rulesService } from "../../../utils/api";
import { toast } from "react-toastify";
import { useUser } from "../../../utils/hooks/redux.hook";

import Waiting from "../../../assets/svg/waiting.svg";

const TimeCheckbok = ({ vendors, ruleTypeId }) => {
  const { user } = useUser();
  const [items, setItems] = React.useState({});
  const [rules, setRules] = React.useState([]);

  const fetchTimeOfDayRules = async () => {
    try {
      const response = await rulesService.getChannelRules();
      console.log(response.data);
      setRules(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    fetchTimeOfDayRules();
  }, []);

  const handleSave = async (rule) => {
    const splitObject = {};

    Object.keys(items).forEach((key) => {
      const [parentKey, childKey] = key.split("+");
      if (!splitObject[parentKey]) {
        splitObject[parentKey] = {};
      }
      splitObject[parentKey][childKey] = items[key];
    });

    const payload = {
      ...rule,
      ...splitObject[rule?.id],
      email: user?.admin?.email,
    };

    console.log(payload);

    if (payload?.startHour > payload?.endHour) {
      toast.error("Start hours can't be greater than end hours", {
        className: "toast-success",
        theme: "colored",
      });
    } else {
      try {
        const response = await rulesService.updateTimeOfDayRule(
          rule?.id,
          payload
        );
        if (response?.status === 200) {
          toast.success("Rule sent for approval", {
            className: "toast-success",
            theme: "colored",
          });
          setItems({});
          fetchTimeOfDayRules();
        } else {
          toast.error("Something went wrong.", {
            className: "toast-error",
            theme: "colored",
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  console.log(items);
  React.useEffect(() => {}, [items]);

  function handleChange(event) {
    setItems({ ...items, [event.target.name]: event.target.value });
  }

  return (
    <div className="ruleSec-formControl">
      <div>
        {rules.map((vendor, i) => (
          <Box
            key={i}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 5,
              mb: 10,
            }}
          >
            <FormControl
              sx={{ color: "#FF543D" }}
              component="fieldset"
              variant="standard"
            >
              {/* <FormLabel component="legend">Assign responsibility</FormLabel> */}
              <FormGroup>
                {/* <FormControlLabel
                //  sx={{marginBottom: '30px'}}
                control={
                  <Checkbox
                    value={vendor?.id}
                    checked={true}
                    sx={{
                      color: deepOrange[800],
                      "&.Mui-checked": {
                        color: deepOrange[600],
                      },
                    }}
                  />
                }
                label={vendor?.name}
              /> */}
                {vendor?.name}
              </FormGroup>
            </FormControl>

            <FormGroup sx={{ flexDirection: "row", alignItems: "center" }}>
              {/* <div className="time">  */}
              <div
                style={{
                  color: "#FF543D",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {<TimerIcon />}
              </div>

              <FormControl sx={{ mx: 1, minWidth: "70px" }} size="small">
                <InputLabel id="time-select-label-small">Start hour</InputLabel>
                <Select
                  labelId="time-select-label-small"
                  label="Start hour"
                  id="time-select-small"
                  name={vendor?.id + "+startHour"}
                  value={items[vendor?.id + "+startHour"] ?? vendor?.startHour}
                  onChange={handleChange}
                >
                  {[...Array(24).keys()].map((hour) => (
                    <MenuItem key={hour} value={hour}>
                      {hour}:00
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                sx={{ mx: 1, minWidth: "70px" }}
                size="small"
                minWidth={"70px"}
              >
                <InputLabel id="time-select-label-small">End hour</InputLabel>
                <Select
                  labelId="time-select-label-small"
                  label="End hour"
                  id="time-select-small"
                  name={vendor?.id + "+endHour"}
                  value={items[vendor?.id + "+endHour"] ?? vendor?.endHour}
                  onChange={handleChange}
                >
                  {[...Array(24).keys()].map((hour) => (
                    <MenuItem key={hour} value={hour}>
                      {hour}:00
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {vendor?.status === "PENDING" ? (
                <img
                  src={Waiting}
                  alt="Waiting Icon"
                  style={{ marginLeft: "8px", marginRight: "14px" }}
                />
              ) : (
                <div style={{ width: "46px" }}></div>
              )}

              <button
                className="ruleSec-reset-btn"
                disabled={
                  vendor?.status === "PENDING" ||
                  vendor?.rule?.startHour !== items[vendor?.id + "+start"] ||
                  vendor?.rule?.endHour !== items[vendor?.id + "+end"]
                }
                onClick={() => handleSave(vendor)}
              >
                Save
              </button>
            </FormGroup>
          </Box>
        ))}
      </div>
      <div>
        <NavLink
          to="/admin/rule-history"
          className="rule-config-history"
          activeclassname="active"
        >
          <p>Rule Change History</p>
          <ArrowForwardIosIcon />
        </NavLink>
      </div>
    </div>
  );
};

export default TimeCheckbok;
