import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";

const TimeOfDayModal = ({ details, onConfirm, setIsRejectOpen, onCancel }) => {
  console.log(details);
  const data = JSON?.parse(details?.data);
  console.log(data);

  return (
    <Grid container py={8} px={6} width={"400px"}>
      <Grid item width={"100%"}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "36px",
            color: "#FF543D",
          }}
        >
          CHANNEL RULE APPROVAL
        </Typography>

        <Box display={"flex"} justifyContent={"space-between"} my={1}>
          <Typography
            sx={{ fontSize: "16px", fontWeight: 600, lineHeight: "36px" }}
          >
            Modifier:
          </Typography>
          <Typography
            sx={{ fontSize: "16px", fontWeight: 600, lineHeight: "36px" }}
          >
            {details?.email}
          </Typography>
        </Box>

        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography sx={{ fontSize: "16px", lineHeight: "36px" }}>
            Vendor:
          </Typography>
          <Typography sx={{ fontSize: "16px", lineHeight: "36px" }}>
            {data?.Name}
          </Typography>
        </Box>

        {}

        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography sx={{ fontSize: "16px", lineHeight: "36px" }}>
            Atm:
          </Typography>
          <Typography sx={{ fontSize: "16px", lineHeight: "36px" }}>
            {data?.Atm === true ? "true" : "false"}
          </Typography>
        </Box>

        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography sx={{ fontSize: "16px", lineHeight: "36px" }}>
            Internet:
          </Typography>
          <Typography sx={{ fontSize: "16px", lineHeight: "36px" }}>
            {data?.Internet === true ? "true" : "false"}
          </Typography>
        </Box>

        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography sx={{ fontSize: "16px", lineHeight: "36px" }}>
            Mobile:
          </Typography>
          <Typography sx={{ fontSize: "16px", lineHeight: "36px" }}>
            {data?.Mobile === true ? "true" : "false"}
          </Typography>
        </Box>

        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography sx={{ fontSize: "16px", lineHeight: "36px" }}>
            Ussd:
          </Typography>
          <Typography sx={{ fontSize: "16px", lineHeight: "36px" }}>
            {data?.Ussd === true ? "true" : "false"}
          </Typography>
        </Box>

        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography sx={{ fontSize: "16px", lineHeight: "36px" }}>
            Date Created:
          </Typography>
          <Typography sx={{ fontSize: "16px", lineHeight: "36px" }}>
            {details?.createdAt?.substring(0, 10) ?? "-"}
          </Typography>
        </Box>

        <Box
          py={2}
          display={details?.approvalStatus === "PENDING" ? "flex" : "none"}
          justifyContent={"space-around"}
        >
          <Button
            variant="contained"
            sx={{
              background: "#B3B3B3",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#B3B3B3",
                color: "white",
                borderColor: "white",
              },
            }}
            onClick={() => {
              setIsRejectOpen(true);
              onCancel();
            }}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            sx={{
              background: "#FF543D",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#FF543D",
                color: "white",
                borderColor: "white",
              },
            }}
            onClick={() => {
              onConfirm(details?.email, details?.id);
            }}
          >
            Approve
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TimeOfDayModal;
