import React, { useState } from "react";
import { Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BankCheckbox from "./BankCheckbox";

const BankCard = () => {
  const [selected, setSelected] = useState("Bank Channels");
  const navigate = useNavigate();

  const handleTimeNavigate = (e) => {
    console.log(e.target.value);
    navigate("/admin/configuration/bank-section");
    setSelected(e.target.value);
  };
  return (
    <div>
      <Card
        sx={{
          borderRadius: "20px",
          width: "94%",
          height: "100vh",
          padding: "30px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", columnGap: "5rem" }}>
            <div className="ruleName">
              <label htmlFor="ruleName">Rule Name</label>
              <div>
                <input
                  className="ruleName-input"
                  type="text"
                  name="ruleName"
                  placeholder="DefaultRule1"
                />
              </div>
            </div>
            <div className="ruleName">
              <div className="ruleName">
                <label htmlFor="ruleType">Rule Type</label>
              </div>

              <select
                value={selected}
                onChange={(e) => handleTimeNavigate(e)}
                name="ruleType"
                className="ruleType-input"
              >
                <option>Network</option>
                <option>Time of Day</option>
                <option>Bank Channels</option>
              </select>
              {selected === "Time of Day"
                ? navigate("/admin/configuration/time-section")
                : selected === "Network"
                ? navigate("/admin/configuration")
                : selected === "Bank Channels"
                ? navigate("/admin/configuration/bank-section")
                : ""}
            </div>
          </div>

          <button className="ruleSec-reset-btn">Reset</button>
        </div>
        <div className="ruleSec-card-header">
          <p>Rule Configuration</p>
        </div>

        <div>
          <BankCheckbox />
        </div>
      </Card>
    </div>
  );
};

export default BankCard;
