// Library methods
import React, { useState, useEffect, useCallback } from "react";
import { CSVLink } from "react-csv";

// Components
import CenterModal from "../../components/modal/center";

// MUI Components
import { Box, Card, Grid, TablePagination, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";

// Utilities
import { settlementService } from "../../utils/api";
import { toast } from "react-toastify";
import SettlementDetail from "../settlement/VendorSettlementDetail";
import RejectionModal from "./modals/RejectionModal";
import exportIcon from "../../assets/svg/dashboard/exportIcon.svg";
import { formatPickerDate } from "../../utils/helpers";

const SettlementSection = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [details, setDetails] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isRejectOpen, setIsRejectOpen] = useState(false);

  const fetchSettlements = useCallback(async () => {
    try {
      const response = await settlementService.getVendorSettlements();

      const filteredData = response?.data
        ?.filter((item) => item?.settlementStatus === "INITIATED")
        .sort((a, b) => b.id - a.id);

      setData(filteredData);
    } catch (error) {
      console.log(error);
    }
  }, []);

  //initiate a settlement config
  const handleApprove = async (email, id) => {
    await settlementService.approveSettlement(email, id).then((res) => {
      if (res?.status === 200) {
        toast.success("Settlement approved successfully", {
          className: "toast-success",
          theme: "colored",
        });
        setIsOpen(false);
        fetchSettlements();
        window.location.reload();
      } else {
        toast.error("Something went wrong.", {
          className: "toast-error",
          theme: "colored",
        });
      }
    });
  };

  //reject a rule config
  const handleReject = async (email, id, reason) => {
    await settlementService.rejectSettlement(email, id, reason).then((res) => {
      if (res?.status === 200) {
        toast.success("Settlement rejected successfully", {
          className: "toast-success",
          theme: "colored",
        });
        setIsRejectOpen(false);
        window.location.reload();
      } else {
        toast.error("Something went wrong.", {
          className: "toast-error",
          theme: "colored",
        });
      }
    });
  };

  const rows = data?.map((item, idx) => {
    return {
      name: item?.VendorName,
      date: `${item?.createdAt?.substring(
        0,
        10
      )} - ${item?.createdAt?.substring(11, 16)}`,
      action: (
        <Button
          variant="contained"
          sx={{
            background: "#255cff",
            color: "#fff",
            textTransform: "capitalize",
            boxShadow: "none",
            fontFamily: "Open-Sans",
          }}
          onClick={() => {
            setDetails(item);
            setIsOpen(true);
          }}
        >
          View Details
        </Button>
      ),
    };
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    fetchSettlements();
  }, [fetchSettlements]);

  return (
    <Card
      sx={{
        borderRadius: "20px",
        width: "98%",
        padding: "30px 0px",
        boxShadow: "none",
      }}
    >
      <Box display={"flex"} justifyContent={"space-between"} my={2}>
        <Typography
          sx={{
            margin: "0px 30px",
            color: "#737373",
            fontSize: "20px",
            fontWeight: "600",
            fontFamily: "Open-Sans",
          }}
        >
          Settlement Required
        </Typography>

        {data?.length > 0 && (
          <div
            className="transaction-header-div-share"
            style={{ marginRight: "30px" }}
          >
            <CSVLink
              data={data}
              filename={`settlement-approval-data_${formatPickerDate(
                new Date()
              )}.csv`}
            >
              <img src={exportIcon} alt="download" />
            </CSVLink>
          </div>
        )}
      </Box>

      <Grid container flexDirection={"column"}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "17px",
                    fontWeight: "600",
                    fontFamily: "Open-Sans",
                  }}
                >
                  Vendor Name
                </TableCell>

                <TableCell
                  sx={{
                    fontSize: "17px",
                    fontWeight: "600",
                    fontFamily: "Open-Sans",
                  }}
                >
                  Date
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "17px",
                    fontWeight: "600",
                    fontFamily: "Open-Sans",
                  }}
                >
                  Actions
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row) => (
                  <TableRow key={row?.name}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ color: "#737373", fontFamily: "Open-Sans" }}
                    >
                      {row?.name}
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          backgroundColor: "#EEF0F4",
                          color: "#737373",
                          borderRadius: "30px",
                          maxWidth: "150px",
                          height: "30px",
                          padding: "0px 5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: "Open-Sans",
                        }}
                      >
                        {row?.date}
                      </div>
                    </TableCell>
                    <TableCell>{row?.action}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>

      <CenterModal
        isOpen={isOpen}
        toggleModal={() => {
          setIsOpen(!isOpen);
        }}
      >
        <SettlementDetail
          details={details}
          onConfirm={handleApprove}
          onCancel={setIsOpen}
          setIsRejectOpen={setIsRejectOpen}
          type={"approve"}
        />
      </CenterModal>

      <CenterModal
        isOpen={isRejectOpen}
        toggleModal={() => {
          setIsRejectOpen(!isRejectOpen);
        }}
      >
        <RejectionModal
          details={details}
          onReject={handleReject}
          setIsRejectOpen={setIsRejectOpen}
        />
      </CenterModal>
    </Card>
  );
};

export default SettlementSection;
