import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Card, Typography, Divider } from "@mui/material";

import TimerIcon from "@mui/icons-material/Timer";

import { settingsService } from "../utils/api";

import "../styles/ruleConfig.css";
import "../styles/settings.css";
import moment from "moment";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import waiting from "../assets/svg/waiting.svg";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Settings = () => {
  const [value, setValue] = useState(0);
  const [data, setData] = useState({});
  const [settingsSwitch, setSettingsSwitch] = useState();

  const creatorEmail = JSON.parse(sessionStorage.getItem("user")).email;

  const fetchSettings = async () => {
    try {
      const response = await settingsService.getSettings();
      setData(response?.data);
      setSettingsSwitch(response?.data?.enabled);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  console.log(data);

  const handleCheckbox = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSettingsChange = (event) => {
    const { name, value } = event.target;
    setData({
      ...data,
      [name]:
        value === "true" || value === "false"
          ? JSON.parse(value)
          : Number(value),
    });
  };
  const handleSettingsUpdate = async () => {
    const payload = {
      enabled: data?.enabled,
      maxFailureRate: data?.maxFailureRate,
      warningTriggerRate: data?.warningTriggerRate,
      reviewWindow: data?.reviewWindow,
      email: creatorEmail,
    };
    try {
      const response = await settingsService.updateSettings(data.id, payload);
      if (response.status === 200) {
        toast.success("Settings update is successfully sent for approval.", {
          className: "toast-success",
          theme: "colored",
        });
        fetchSettings();
      } else {
        toast.error("Something went wrong.", {
          className: "toast-error",
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error("Something went wrong.", {
        className: "toast-error",
        theme: "colored",
      });
    }
  };

  return (
    <div className="dashboard-container">
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingRight: "15px",
          }}
        >
          <div>
            <h2 className="dash-header">Settings</h2>
          </div>
          <div style={{ display: "flex", columnGap: "20px" }}>
            <div className="time">
              <TimerIcon />
              <p>Time: {moment().format("h:mm:ss a")}</p>
            </div>
          </div>
        </div>

        <div>
          <p className="ruleConfig-header">
            Admin has the ability to customize the global settings for this
            application. You can manually adjust the following;
          </p>
        </div>
        <div className="ruleConfig-tabs">
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{
              sx: { backgroundColor: "#255cff", height: 4 },
            }}
            sx={{
              width: "90%",
              margin: "0 auto",
              height: "72px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              "& button:active": { color: "#255cff" },
              "& button.Mui-selected": { color: "#255cff" },
            }}
          >
            <Tab
              label="Global Settings"
              {...a11yProps(0)}
              sx={{
                fontSize: "20px",
                color: "#BBBBBB",
                fontWeight: "600",
                fontFamily: "Open-Sans",
              }}
            />
          </Tabs>
        </div>

        <Box sx={{ width: "100%" }}>
          <TabPanel value={value} index={0}>
            <Card
              sx={{
                width: "98%",
                height: "879px",
                borderRadius: "20px",
                boxShadow: "none",
              }}
            >
              <div className="disable-container">
                <Typography
                  sx={{
                    color: "#000000",
                    fontFamily: "Open-Sans",
                    fontWeight: "600",
                    fontSize: "18px",
                    lineHeight: "27px",
                  }}
                >
                  Enable
                </Typography>
                <div className="disable-select-container">
                  <div className="disable-select">
                    <p>NO</p>
                    <div className="disable-select-cont">
                      <input
                        value={settingsSwitch}
                        checked={data?.enabled}
                        type="checkbox"
                        id="check"
                        name="enabled"
                        onClick={() => setSettingsSwitch(!settingsSwitch)}
                        onChange={(e) => handleCheckbox(e)}
                      />
                      <label
                        for="check"
                        className="disable-select-button"
                      ></label>
                    </div>
                    <p>YES</p>
                  </div>
                </div>
              </div>
              <Divider />
              <div className="rate-container">
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    columnGap: "100px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontFamily: "Open-Sans",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "27px",
                    }}
                  >
                    Max Failure Rate:
                  </Typography>
                  <select
                    name="maxFailureRate"
                    id=""
                    value={data.maxFailureRate}
                    onChange={handleSettingsChange}
                  >
                    {[10, 15, 20, 25].map((value) => (
                      <option key={value} value={value}>
                        {value}%
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  style={{
                    marginTop: "25px",
                    display: "flex",
                    alignItems: "center",
                    columnGap: "13px",
                  }}
                >
                  <ErrorOutlineIcon
                    sx={{ width: "15px", height: "15px", color: "#FF543D" }}
                  />
                  <Typography
                    sx={{
                      color: "#737373",
                      fontFamily: "Open-Sans",
                      fontSize: "14px",
                    }}
                  >
                    Any failure beyond this rate is not acceptable.The vendor
                    will be temporarily disabled.
                  </Typography>
                </div>
              </div>
              <Divider />
              <div
                className="rate-container"
                style={{ padding: "19px 39px 25px" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    columnGap: "75px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontFamily: "Open-Sans",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "27px",
                    }}
                  >
                    Warning Threshold:
                  </Typography>
                  <select
                    name="warningTriggerRate"
                    id=""
                    value={data.warningTriggerRate}
                    onChange={handleSettingsChange}
                  >
                    {[3, 5, 7, 10].map((value) => (
                      <option key={value} value={value}>
                        {value}%
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  style={{
                    marginTop: "25px",
                    display: "flex",
                    alignItems: "center",
                    columnGap: "13px",
                  }}
                >
                  <ErrorOutlineIcon
                    sx={{ width: "15px", height: "15px", color: "#FF543D" }}
                  />
                  <Typography
                    sx={{
                      color: "#737373",
                      fontFamily: "Open-Sans",
                      fontSize: "14px",
                    }}
                  >
                    Service Degradation Email will be sent to all stakeholders.
                  </Typography>
                </div>
              </div>
              <Divider />
              <div className="rate-container" style={{ padding: "21px 39px" }}>
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    columnGap: "105px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000000",
                      fontFamily: "Open-Sans",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "27px",
                    }}
                  >
                    Review Window:
                  </Typography>
                  <select
                    name="reviewWindow"
                    id=""
                    value={data.reviewWindow}
                    onChange={handleSettingsChange}
                  >
                    {[1, 2, 4].map((value) => (
                      <option key={value} value={value}>
                        {value} Hrs
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <Divider />
              <div className="settings-btn-cont">
                {data?.status === "PENDING" && (
                  <img src={waiting} alt="" style={{ marginRight: "10px" }} />
                )}
                <button
                  className="settings-btn"
                  onClick={handleSettingsUpdate}
                  disabled={data?.status === "PENDING"}
                >
                  Apply
                </button>
              </div>
            </Card>
          </TabPanel>
        </Box>
      </div>
      <p className="copyright-text">&copy; 2024 Breeze Transaction Gateway. </p>
    </div>
  );
};

export default Settings;
