import React, { useState, useEffect } from "react";
import { Card, FormControlLabel, Checkbox, Divider } from "@mui/material";

import { vendorService } from "../../../utils/api";

const AddNewVendor = ({ setNewVendorState }) => {
  const [checkedValue, setCheckedValue] = useState(true);
  function handleChecked(event) {
    // const {value, checked, label} = event.target
    setCheckedValue(!checkedValue);
  }

  const initialValues = {
    name: "",
    settlementAccount: "",
    apiIntegration: true,
    email: "",
    phone: "",
    comment: "",
    city: "",
    country: "",
    isActive: Boolean(true),
  };

  const validate = (values) => {
    // validate(formValues)
    const errors = {};
    const regex = /^[^\s@]+[^\s@]+\.[^\s@]{2,}$/;
    if (!values.name) {
      errors.name = " Vendor's name is required";
    }

    if (!values.settlementAccount) {
      errors.settlementAccount = "Settlement Account is required";
    }

    if (!values.email) {
      errors.email = "Vendor's Email  is required";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format";
    }

    if (!values.phone) {
      errors.phone = "Phone Number is required";
    }

    if (!values.comment) {
      errors.comment = "Message is required";
    }

    return errors;
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setformErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(true);
  const handleIsActive = (e) => {
    // const {name, value} = e.target;
    // const trueValue = Boolean(true)
    // const falseValue = Boolean(false)
  };

  const handleVendorChange = (e) => {
    const { name, value } = e.target;

    console.log(name, typeof value);
    setFormValues({ ...formValues, [name]: value });
  };
  useEffect(() => {
    setFormValues({ ...formValues, apiIntegration: checkedValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedValue]);

  useEffect(() => {
    setFormValues({ ...formValues, isActive: Boolean(formValues.isActive) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.isActive]);

  // useEffect(() => {

  // },)

  const handleVendorSubmit = async (e) => {
    e.preventDefault();
    setformErrors(validate(formValues));
    setIsSubmit(true);
    try {
      const { data } = await vendorService.addVendors(formValues);
      console.log(data);
      setNewVendorState(false);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    handleVendorSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);

  return (
    <div style={{ width: "93%", marginBottom: "30px" }}>
      <Card
        sx={{
          width: "100%",
          height: "100%",
          paddingTop: "39px",
          paddingBottom: "39px",
          boxShadow: "none",
        }}
      >
        <div className="" style={{ width: "90%", margin: "0 auto" }}>
          <form className="add-vendor-forms" onSubmit={handleVendorSubmit}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "22px",
                marginTop: "22px",
              }}
            >
              <div>
                <label htmlFor="name">Vendor Name</label>
              </div>
              <div>
                <input
                  type="text"
                  name="name"
                  placeholder="input vendor name"
                  onChange={handleVendorChange}
                  value={formValues.name}
                />
                <p style={{ color: "red", paddingTop: "-40px" }}>
                  {formErrors.name}
                </p>
              </div>
            </div>

            <Divider />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "22px",
                marginTop: "22px",
                width: "100",
              }}
            >
              <div>
                <label htmlFor="settlement account">Settlement Account</label>
              </div>
              <div>
                <input
                  type="number"
                  name="settlementAccount"
                  placeholder="input account"
                  onChange={handleVendorChange}
                  value={formValues.settlementAccount}
                />
                <p style={{ color: "red", paddingTop: "-40px" }}>
                  {formErrors.settlementAccount}
                </p>
              </div>
            </div>

            <Divider />

            <div
              style={{
                display: "flex",
                columnGap: "2rem",
                marginBottom: "22px",
                marginTop: "22px",
              }}
            >
              <div>
                <label htmlFor="api integration">API Integration</label>
              </div>
              <div style={{ position: "relative", left: "365px" }}>
                <FormControlLabel
                  label="Yes"
                  control={
                    <Checkbox
                      name="apiIntegration"
                      sx={{
                        color: "#737373",
                        "&.Mui-checked": {
                          color: "#255cff",
                        },
                      }}
                      onChange={handleChecked}
                      value={"Yes"}
                      checked={checkedValue}
                    />
                  }
                />

                <FormControlLabel
                  label={"No"}
                  control={
                    <Checkbox
                      name="apiIntegration"
                      // {...label}
                      // defaultChecked
                      sx={{
                        color: "#737373",
                        "&.Mui-checked": {
                          color: "#255cff",
                        },
                      }}
                      onChange={handleChecked}
                      value={"No"}
                      checked={!checkedValue}
                    />
                  }
                />
              </div>
            </div>

            <Divider />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "22px",
                marginTop: "22px",
              }}
            >
              <div>
                <label htmlFor="set active">Set to Active</label>
              </div>
              <div>
                <select
                  name="isActive"
                  onChange={handleIsActive}
                  value={formValues.isActive}
                >
                  <option value={Boolean(true)}>True</option>
                  <option value={Boolean(false)}>False</option>
                </select>
                <p style={{ color: "red", paddingTop: "-40px" }}>
                  {formErrors.isActive}
                </p>
              </div>
            </div>

            <Divider />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "22px",
                marginTop: "22px",
              }}
            >
              <div>
                <label htmlFor="vendor email">Vendor Email</label>
              </div>
              <div>
                <input
                  type="email"
                  name="email"
                  placeholder="Input vendor email"
                  onChange={handleVendorChange}
                  value={formValues.email}
                />
                <p style={{ color: "red", paddingTop: "-40px" }}>
                  {formErrors.email}
                </p>
              </div>
            </div>

            <Divider />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "22px",
                marginTop: "22px",
              }}
            >
              <div>
                <label htmlFor="vendor phone">Vendor Phone Contact</label>
              </div>
              <div>
                <input
                  type="number"
                  name="phone"
                  placeholder="Input vendor phone contact"
                  onChange={handleVendorChange}
                  values={formValues.phone}
                />
                <p style={{ color: "red", paddingTop: "-40px" }}>
                  {formErrors.phone}
                </p>
              </div>
            </div>

            <Divider />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "22px",
                marginTop: "22px",
              }}
            >
              <div>
                <label htmlFor="city">City</label>
              </div>
              <div>
                <input
                  type="text"
                  name="city"
                  placeholder="city"
                  onChange={handleVendorChange}
                  values={formValues.city}
                />
              </div>
            </div>

            <Divider />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "22px",
                marginTop: "22px",
              }}
            >
              <div>
                <label htmlFor="country">Country</label>
              </div>
              <div>
                <select
                  name="country"
                  onChange={handleVendorChange}
                  value={formValues.country}
                >
                  <option selected>select country</option>
                  <option>Nigeria</option>
                </select>
              </div>
            </div>

            <Divider />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "22px",
                marginTop: "22px",
              }}
            >
              <div>
                <label htmlFor="comment">Comments About Vendor</label>
              </div>
              <div>
                <input
                  type="text"
                  name="comment"
                  id=""
                  placeholder="type here...."
                  onChange={handleVendorChange}
                  value={formValues.comment}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                columnGap: "20px",
                marginTop: "3rem",
              }}
            >
              <button
                // onClick={(e) => {
                //   e.preventDefault()
                // navigate('/admin/add-all-vendors');
                // }}
                type="submit"
                className="vendorSec-btn"
                style={{ background: "#255cff" }}
              >
                Save
              </button>
              <button
                type="button"
                className="vendorSec-btn"
                onClick={() => setNewVendorState(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};

export default AddNewVendor;
