import React, { useState, useEffect } from "react";
import logo from "../../assets/svg/logo.svg";
import validate from "./Validation";
import "../../styles/adminLogin.css";
import loginImg from "../../assets/svg/loginImg.svg";
import { authService, wakeService } from "../../utils/api";

import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });
  const [formErrors, setformErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const regex = /[^A-Za-z0-9]/g;

  const handleServerWake = async () => {
    await wakeService.wakeCall();
  };

  useEffect(() => {
    handleServerWake();
  }, []);

  const randomIV = () => {
    return CryptoJS.lib.WordArray.random(16);
  };

  const iv = randomIV();

  const encrypt = (plainTest, key, iv) => {
    const keyWordArray = CryptoJS.enc.Utf8.parse(key);

    const encrypted = CryptoJS.AES.encrypt(plainTest, keyWordArray, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    const encrytpedData = iv
      .concat(encrypted.ciphertext)
      .toString(CryptoJS.enc.Base64);

    return encrytpedData;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      if (
        formValues?.email === "" ||
        formValues?.email.length < 3 ||
        formValues?.email.length > 20 ||
        regex.test(formValues?.email) ||
        formValues?.password === "" ||
        formValues?.password.length < 3 ||
        formValues?.password.length > 20
      ) {
        setformErrors(validate(formValues));
        setLoading(false);
      } else {
        const payload = {
          email: formValues.email,
          password: encrypt(
            formValues.password,
            process.env.REACT_APP_API_KEY,
            iv
          ),
        };

        const { data } = await authService.login(payload);
        if (data?.succeeded === true) {
          sessionStorage.setItem("username", formValues.email);

          setLoading(false);
          toast.success("Successfully signed in", {
            className: "toast-success",
            theme: "colored",
          });
          navigate("/auth/authentication");
        } else if (data?.succeeded === false) {
          setLoading(false);
          toast.error(data.message, {
            className: "toast-error",
            theme: "colored",
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-form-bg">
        <div className="breezelearnLogo">
          <img src={logo} alt="logo" />
        </div>
        <div>
          <div className="login-form-header">
            <p>Login to Your Dashboard </p>
          </div>
          <p className="login-form-sub-header">
            Enter Your Credentials to Access Your Account
          </p>

          <div className="form-group">
            <form onSubmit={handleSubmit}>
              <div className="username-input">
                <label htmlFor="username">Username*</label>
                <br></br>
                <input
                  type="text"
                  placeholder="Administrator"
                  className="login-input"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  value={formValues.username}
                />
                <p style={{ color: "red", paddingTop: "-40px" }}>
                  {formErrors.email}
                </p>
              </div>

              <div className="password-input">
                <label htmlFor="password">Password*</label>
                <br></br>
                <input
                  type="password"
                  className="login-input"
                  id="password"
                  name="password"
                  onChange={handleChange}
                  value={formValues.password}
                />
                <p style={{ color: "red", paddingTop: "-40px" }}>
                  {formErrors.password}
                </p>
              </div>
              <button className="login-btn" type="submit">
                {loading ? "Please Wait..." : "Login"}
              </button>
            </form>
          </div>
        </div>
      </div>

      <div className="login-img-bg">
        <img className="login-img" src={loginImg} alt="" />
      </div>
    </div>
  );
};

export default AdminLogin;
