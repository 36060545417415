import React, { useState, useEffect, useMemo, useRef } from "react";

import logo from "../../assets/svg/logo.svg";

import "../../styles/adminLogin.css";
import loginImg from "../../assets/svg/loginImg.svg";
import { authService } from "../../utils/api";
import { useUser } from "../../utils/hooks/redux.hook";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Authentication = () => {
  const { setUserDispatch } = useUser();
  const username = sessionStorage.getItem("username");

  const [otpValue, setOtpValues] = useState("");

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const inputRefs = useRef([]);
  const valueLength = 6;

  const valueItems = useMemo(() => {
    const valueArray = otpValue?.split("");
    const items = [];
    const DIGIT_TEST = new RegExp(/^\d+$/);

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (DIGIT_TEST?.test(char)) {
        items.push(char);
      } else {
        items.push("");
      }
    }

    return items;
  }, [otpValue, valueLength]);

  useEffect(() => {
    if (inputRefs.current.length > 0) {
      inputRefs.current[0]?.focus();
    }
  }, []);

  const focusToNextInput = (target) => {
    const nextElementSibling = target.nextElementSibling;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };
  const focusToPrevInput = (target) => {
    const previousElementSibling = target.previousElementSibling;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  const handleChange = (e, idx) => {
    const target = e.target;
    let targetValue = target.value.trim();
    target.focus();
    const DIGIT_TEST = new RegExp(/^\d+$/);

    const isTargetValueDigit = DIGIT_TEST?.test(targetValue);

    if (!isTargetValueDigit && targetValue !== "") {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : " ";

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue =
        otpValue.substring(0, idx) + targetValue + otpValue.substring(idx + 1);

      setOtpValues(newValue);

      if (!isTargetValueDigit) {
        return;
      }

      focusToNextInput(target);
    } else if (targetValueLength === valueLength) {
      setOtpValues(targetValue);
      target.blur();
    }
  };

  const handleDeleteFocus = (e) => {
    const { key } = e;
    const target = e.target;

    if (key === "ArrowRight" || key === "ArrowDown") {
      e.preventDefault();
      return focusToNextInput(target);
    }

    if (key === "ArrowLeft" || key === "ArrowUp") {
      e.preventDefault();
      return focusToPrevInput(target);
    }

    const targetValue = target.value;

    target.setSelectionRange(0, target.value.length);

    if (e.key !== "Backspace" || targetValue !== "") {
      return;
    }

    focusToPrevInput(target);

    const previousElementSibling = target.previousElementSibling;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const payload = {
      code: otpValue,
      username: username,
    };
    const { data } = await authService.authentication(payload);

    if (data?.succeeded) {
      setUserDispatch(data?.data);
      sessionStorage.setItem("user", JSON.stringify(data?.data));
      setLoading(false);
      toast.success("Authentication successful.", {
        className: "toast-success",
        theme: "colored",
      });
      switch (data?.data.roles[0]) {
        case "SUPERADMIN":
          navigate("/admin/dashboard-active");
          break;
        case "INITIATOR":
          navigate("/admin/dashboard-active");
          break;
        case "SETTLEMENT_INITIATOR":
          navigate("/admin/settlement");
          break;
        case "SETTLEMENT_APPROVER":
          navigate("/admin/settlement-approval");
          break;
        case "APPROVER":
          navigate("/admin/dashboard-active");
          break;
        case "USERMANAGER_INITIATOR":
          navigate("/admin/users");
          break;
        case "USERMANAGER_APPROVER":
          navigate("/admin/user-approval");
          break;
        case "AUDITOR":
          navigate("/admin/activity");
          break;
        default:
          navigate("/admin/error");
          break;
      }
    } else {
      setLoading(false);
      toast.error(data?.message, {
        className: "toast-error",
        theme: "colored",
      });
    }
  };

  return (
    <div className="login-container">
      <div className="login-form-bg">
        <div className="breezelearnLogo">
          <img src={logo} alt="logo" />
        </div>
        <div>
          <div className="login-form-header" style={{ marginTop: "55px" }}>
            <p>Two Factor Authentication</p>
          </div>
          <p className="authentication-form-sub-header">
            Protecting your account is our top priority. Kindly generate your
            OTP and enter below
          </p>

          <div className="authentication-form-group">
            <form onSubmit={handleSubmit}>
              <div className="authentication-input-cont">
                {valueItems.map((digit, idx) => (
                  <input
                    key={idx}
                    type="text"
                    name="code"
                    inputMode="numeric"
                    autoComplete="one-time-code"
                    pattern="\d{1}"
                    maxLength={valueLength}
                    className="authentication-input"
                    value={digit}
                    onChange={(e) => handleChange(e, idx)}
                    onKeyDown={handleDeleteFocus}
                    ref={(ref) => (inputRefs.current[idx] = ref)}
                  />
                ))}
              </div>
              <button
                className="login-btn"
                type="submit"
                style={{ maxWidth: "400px", marginLeft: "8px" }}
                disabled={otpValue === "" || otpValue.length < 6}
              >
                {loading ? "Please Wait..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>

      <div className="login-img-bg">
        <img className="login-img" src={loginImg} alt="" />
      </div>
    </div>
  );
};

export default Authentication;
