import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useUser } from "../../../utils/hooks/redux.hook";

const RejectionModal = ({ details, onReject, setIsRejectOpen }) => {
  const { admin } = useUser();
  const [reason, setReason] = useState("");
  const actorEmail = JSON.parse(sessionStorage.getItem("user")).email;

  console.log(reason);
  console.log(details);
  return (
    <Grid container p={4} width={"400px"}>
      <Grid item width={"100%"}>
        <Typography
          my={2}
          sx={{ fontSize: "18px", fontWeight: 600, fontFamily: "Open-Sans" }}
        >
          Rejection Reason
        </Typography>

        <TextField
          id="my-textarea"
          label="Enter a rejection reason for the initiator"
          multiline
          rows={4}
          fullWidth
          onChange={(e) => setReason(e.target.value)}
        />
      </Grid>
      <Box py={2} display={"flex"} justifyContent={"right"} width={"100%"}>
        <Button
          variant="contained"
          sx={{
            background: "#fff",
            textTransform: "capitalize",
            color: "#255cff",
            fontFamily: "Open-Sans",
            border: "2px solid #255cff",
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "#B3B3B3",
            },
          }}
          onClick={() => {
            setIsRejectOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onReject(actorEmail ?? admin?.admin?.email, details?.id, reason);
          }}
          sx={{
            background: "#255cff",
            textTransform: "capitalize",
            fontFamily: "Open-Sans",
            "&:hover": {
              backgroundColor: "#255cff",
            },
            ml: 2,
          }}
          disabled={reason === ""}
        >
          Submit
        </Button>
      </Box>
    </Grid>
  );
};

export default RejectionModal;
