import React, { useState } from "react";

import TimerIcon from "@mui/icons-material/Timer";

import { encryptService } from "../utils/api";

import moment from "moment";
import Box from "@mui/material/Box";

import "../styles/ruleConfig.css";
import "../styles/settings.css";
import "../styles/textEncryption.css";

const TextEncryption = () => {
  const [encryptTextInput, setEncryptTextInput] = useState("");
  const [encryptTextOuput, setEncryptTextOuput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleEncrypt = async () => {
    setIsLoading(true);
    const payload = {
      item: encryptTextInput,
    };
    try {
      const response = await encryptService.encrypt(payload);
      setEncryptTextOuput(response?.data?.encryptedKey);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="dashboard-container">
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingRight: "15px",
          }}
        >
          <div>
            <h2 className="dash-header">Encryption Utility</h2>
          </div>
          <div style={{ display: "flex", columnGap: "20px" }}>
            <div className="time">
              <TimerIcon />
              <p>Time: {moment().format("h:mm:ss a")}</p>
            </div>
          </div>
        </div>

        <div>
          <p className="ruleConfig-header">
            Enter any text you want to encrypt.
          </p>
        </div>

        <Box
          sx={{
            width: "100%",
          }}
        >
          <div className="text-encrypt-input-container">
            <textarea
              name="input"
              value={encryptTextInput}
              onChange={(e) => setEncryptTextInput(e.target.value)}
              placeholder="Enter plain text to be encrypted"
              className="text-encrypt-input"
            ></textarea>

            <button onClick={handleEncrypt} className="text-encrypt-btn">
              {isLoading ? "Please wait..." : "Encrypt"}
            </button>
            <textarea
              name="output"
              value={encryptTextOuput}
              placeholder="Encrypted text result"
              className="text-encrypt-input"
            >
              {encryptTextOuput}
            </textarea>
          </div>
        </Box>
      </div>
      <p className="copyright-text">&copy; 2024 Breeze Transaction Gateway. </p>
    </div>
  );
};

export default TextEncryption;
